import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

const Testimonial = ({ currentPhoto, data }) => {
	return (
		<div className='bg-white lg:py-6 md:-mx-20 lg:-mx-36 xl:-mx-64'>
			<div className='pb-16 bg-pink-300 lg:relative lg:z-10 lg:pb-0'>
				<div>
					<div className='relative'>
						<div aria-hidden='true' className='absolute inset-x-0 top-0 bg-white h-1/2' />
						<div className='max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6'>
							<div className='overflow-hidden shadow-xl aspect-w-10 aspect-h-10 rounded-xl sm:aspect-w-10 sm:aspect-h-10'>
								{currentPhoto && (
									<GatsbyImage
										className='object-cover lg:h-full lg:w-full'
										image={currentPhoto.node.childImageSharp.gatsbyImageData}
										key={`image-feedback-${data.name}`}
										alt={currentPhoto.node.alt || 'No image description set.'}
									/>
								)}
							</div>
						</div>
					</div>
					<div className='pb-8 mt-8'>
						<div className='max-w-md px-4 mx-auto sm:max-w-2xl sm:px-6 lg:max-w-4xl'>
							<blockquote>
								<div>
									<svg className='w-12 h-12 text-white opacity-25' fill='currentColor' viewBox='0 0 32 32' aria-hidden='true'>
										<path d='M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z' />
									</svg>
									<p className='mt-6 text-2xl font-medium text-white'>{data.text}</p>
								</div>
								<footer className='mt-6'>
									<p className='text-base font-medium text-white'>{data.name}</p>
									<p className='text-base font-medium text-pink-100'>{data.date}</p>
								</footer>
							</blockquote>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Testimonial
