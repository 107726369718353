import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import Lightbox from 'lightbox-react'

import '../styles/gallery.css'

const PhotoGalleryComponent = ({ data }) => {
	const images = data.map((node, i) => {
		return (
			<div
				className='inline-flex h-auto overflow-hidden rounded-lg shadow-lg cursor-pointer hover:shadow-xl dark:bg-gray-900 dark:text-gray-50'
				onClick={() => {
					setLightboxIndex(i)
					setIsOpen(true)
				}}
				aria-hidden='true'
				key={`gallery-image-${i}`}
			>
				<GatsbyImage image={node.node.childImageSharp.gatsbyImageData} key={`image-grid-photo-${i}`} alt={node.alt || 'No image description set.'} />
				{/* <p className="px-4 pt-3 truncate">{node.caption}</p> */}
			</div>
		)
	})

	const lightboxImages = data.map((node, i) => {
		return (
			<div className='w-full h-full' key={`lightbox-image-${i}`}>
				<GatsbyImage
					image={node.node.childImageSharp.gatsbyImageData}
					key={`lightbox-image-grid-photo-${i}`}
					className='w-full h-full'
					layout={'fullWidth'}
					imgStyle={{
						objectFit: 'contain',
					}}
				/>
			</div>
		)
	})

	const lightboxCaptions = data.map((node, i) => {
		return <p className='m-0 font-semibold text-center font-body'>{node.caption}</p>
	})

	const [lightboxIndex, setLightboxIndex] = useState(-1)
	const [isOpen, setIsOpen] = useState(false)

	return (
		<>
			<div className='grid grid-flow-row-dense grid-cols-2 gap-4 md:-mx-20 lg:-mx-36 xl:-mx-64 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>{images}</div>
			{isOpen && (
				<Lightbox
					mainSrc={lightboxImages[lightboxIndex]}
					nextSrc={lightboxImages[(lightboxIndex + 1) % lightboxImages.length]}
					prevSrc={lightboxImages[(lightboxIndex + lightboxImages.length - 1) % lightboxImages.length]}
					onCloseRequest={() => setIsOpen(false)}
					onMovePrevRequest={() => setLightboxIndex((lightboxIndex + lightboxImages.length - 1) % lightboxImages.length)}
					onMoveNextRequest={() => setLightboxIndex((lightboxIndex + 1) % lightboxImages.length)}
					enableZoom={false}
					imageCaption={lightboxCaptions[lightboxIndex]}
				/>
			)}
		</>
	)
}

export default PhotoGalleryComponent
