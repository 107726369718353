import React from 'react'

import loadable from '@loadable/component'
const Scroll = loadable(() => import('react-scroll-to-element'))

function NavbarComponent({ scroll }) {
  return (
    <div
      className={
        'sticky top-2 flex justify-center items-center justify-items-center z-50'
      }
    >
      <nav
        className={
          'inline-flex bg-white rounded-full shadow-lg justify-center text-lg'
        }
        role='group'
      >
        {/* <Scroll type='id' element='section-trauung'>
          <button className='px-1 py-2 pl-3 mx-0 text-xs text-pink-300 bg-white border border-r-0 border-pink-300 rounded-l-full outline-none sm:px-2 text-semibold md:py-1 md:text-xl focus:outline-none hover:bg-pink-300 hover:text-white focus:shadow-outline'>
            Start
          </button>
        </Scroll> */}
        <Scroll type='id' element='section-ablauf'>
          <button className='px-1 py-2 pl-3 mx-0 text-xs text-pink-300 bg-white border border-r-0 border-pink-300 rounded-l-full outline-none sm:px-2 text-semibold md:py-1 md:text-xl focus:outline-none hover:bg-pink-300 hover:text-white focus:shadow-outline'>
            Ablauf
          </button>
        </Scroll>
        {/* <Scroll type='id' element='section-ablauf'>
          <button className='px-1 py-2 mx-0 text-xs text-pink-300 bg-white border border-pink-300 outline-none sm:px-2 text-semibold md:py-1 md:text-xl focus:outline-none hover:bg-pink-300 hover:text-white focus:shadow-outline'>
            Ablauf
          </button>
        </Scroll> */}
        <Scroll type='id' element='section-kosten'>
          <button className='px-1 py-2 mx-0 text-xs text-pink-300 bg-white border border-pink-300 outline-none sm:px-2 text-semibold md:py-1 md:text-xl focus:outline-none hover:bg-pink-300 hover:text-white focus:shadow-outline'>
            Kosten
          </button>
        </Scroll>
        <Scroll type='id' element='section-uebermich'>
          <button className='px-1 py-2 mx-0 text-xs text-pink-300 bg-white border border-l-0 border-pink-300 outline-none sm:px-2 text-semibold md:py-1 md:text-xl focus:outline-none hover:bg-pink-300 hover:text-white focus:shadow-outline'>
            Über mich
          </button>
        </Scroll>
        <Scroll type='id' element='section-galerie'>
          <button className='px-1 py-2 mx-0 text-xs text-pink-300 bg-white border border-l-0 border-pink-300 outline-none sm:px-2 text-semibold md:py-1 md:text-xl focus:outline-none hover:bg-pink-300 hover:text-white focus:shadow-outline'>
            Galerie
          </button>
        </Scroll>
        <Scroll type='id' element='section-feedback'>
          <button className='px-1 py-2 mx-0 text-xs text-pink-300 bg-white border border-l-0 border-pink-300 outline-none sm:px-2 text-semibold md:py-1 md:text-xl focus:outline-none hover:bg-pink-300 hover:text-white focus:shadow-outline'>
            Feedback
          </button>
        </Scroll>
        <Scroll type='id' element='section-kontakt'>
          <button className='px-1 py-2 pr-3 mx-0 text-xs text-pink-300 bg-white border border-l-0 border-pink-300 rounded-r-full outline-none sm:px-2 text-semibold md:py-1 md:text-xl focus:outline-none hover:bg-pink-300 hover:text-white focus:shadow-outline'>
            Kontakt
          </button>
        </Scroll>
      </nav>
    </div>
  )
}

export default NavbarComponent
