import * as React from 'react'

function SectionComponent(props) {
	const { icon, iconLabel, title, subtitle, children, id } = props

	return (
		<section className={`my-20 max-w-2xl ml-auto mr-auto px-4 pt-20 ${id === 'section-trauung' ? '' : '-mt-20'}`} id={id}>
			<div className={'flex justify-center flex-col items-center'}>
				<div className={'rounded-full border-pink-200 bg-white border-4 w-24 h-24 flex justify-center items-center shadow-md'}>
					<span role={'img'} className={'text-5xl'} aria-label={iconLabel}>
						{icon}
					</span>
				</div>
				<div className={'text-left w-full'}>
					<div className={'text-center'}>
						<h2 className={'rounded-full shadow-sm mt-3 bg-pink-200 inline-block px-3 py-1 font-bold text-xl mb-0'}>{title}</h2>
						<div>
							<h3 className={'mt-3 mb-6 font-semibold bg-gray-100 inline-block rounded-full shadow-sm px-3 py-1'}>{subtitle}</h3>
						</div>
					</div>
					<div className={'container tracking-normal leading-relaxed'}>{children}</div>
				</div>
			</div>
		</section>
	)
}

export default SectionComponent
