import React, { useState } from 'react'
import { graphql } from 'gatsby'

import { Helmet } from 'react-helmet'
import { AnimatedBg, Transition } from 'scroll-background'
import { Waypoint } from 'react-waypoint'

import SectionComponent from '../components/section'
import ConfettiComponent from '../components/confetti'
import NavbarCompoinent from '../components/navbar'
import ContactFormComponent from '../components/contactForm'
import PhotoGalleryComponent from '../components/photoGallery'
import Testimonials from '../components/testimonials'
import Logo from '../images/logo'

const IndexPage = ({ data }) => {
	const [isPlaying, setIsPlaying] = useState(true)
	const galleryPhotos = data.allFile.edges.filter((el) => el.node.relativePath.includes('gallery'))
	const feedbackPhotos = data.allFile.edges.filter((el) => el.node.relativePath.includes('feedback'))

	return (
		<main>
			<Helmet>
				<meta content='#DCB4C0' name='theme-color' />
				<meta content='Einfach unvergessliche Trauungen. Einzigartig, wie eure Geschichte.' name='description' />
				<meta content='index, follow' name='robots' />
				<meta content='Traurednerin, Freie, Trauung, Hochzeit, Rheintal, freie Trauung' name='keywords' />
				<meta content='https://www.livia-traut.ch/' property='og:url' />
				<meta content='Livia traut.' property='og:site_name' />
				<meta content='Livia Sinz' name='author' />
				<link href={require('../images/favicon.ico').default} rel='shortcut icon' />
				<link href={require('../images/apple-touch-icon.png').default} rel='apple-touch-icon' sizes='180x180' />
				<link href={require('../images/favicon-32x32.png').default} rel='icon' sizes='32x32' type='image/png' />
				<link href={require('../images/favicon-16x16.png').default} rel='icon' sizes='16x16' type='image/png' />
				<link href={require('../images/safari-pinned-tab.svg').default} rel='mask-icon' color='#DCB4C0' />
				<meta content='#DCB4C0' name='msapplication-TileColor' />
				<meta content='#DCB4C0' name='theme-color' />
				<meta property='og:image' content={require('../images/social-image.png').default} />
				<title>Livia traut | Freie Traureden.</title>
			</Helmet>
			<AnimatedBg>
				<Transition height='100vh' from='#DCB4C0' to='#FFF' position={0}>
					<div className={'w-full flex-col h-screen inset-0 items-center p-4 flex justify-center'}>
						<div className={'w-2/5 mb-8'}>
							<Waypoint onEnter={() => setIsPlaying(true)} onLeave={() => setIsPlaying(false)} />
							<Logo />
						</div>
						<h1 className={'text-white font-bold text-3xl md:text-7xl text-center max-w-6xl'}>
							Einfach unvergessliche Trauungen. Einzigartig, wie eure Geschichte.
						</h1>
					</div>
				</Transition>
				<NavbarCompoinent />
				<SectionComponent id={'section-trauung'} icon={'💖'} iconLabel={'Heart'} title={'Die freie Trauung'} subtitle={'Weil eure Geschichte es wert ist.'}>
					<p>
						Ihr wünscht eine feierliche Zeremonie, möchtet euch das Ja-Wort aber nicht in der Kirche geben? Dann seid ihr bei mir genau richtig. Wo auch immer
						eure freie Trauung stattfinden soll – ich bin da. Eure Geschichte steht im Vordergrund. Gerne können wir uns bei einem unverbindlichen Gespräch
						kennenlernen und über die Möglichkeiten einer freien Trauung sprechen. Ihr habt bereits Ideen? Umso besser! Falls nicht, kein Problem. Vielleicht
						kann ich euch ja mit meinen kreativen Vorschlägen begeistern.
					</p>
				</SectionComponent>
				<Waypoint
					onEnter={() => setIsPlaying(false)}
				// onLeave={() => setIsPlaying(true)}
				/>
				<SectionComponent id={'section-ablauf'} icon={'🗓️'} iconLabel={'Calendar'} title={'Ablauf und Planung'} subtitle={'Jede Trauung ist einzigartig.'}>
					<p>
						Ihr meldet euch via E-Mail, per{' '}
						<a href='https://api.whatsapp.com/send?phone=41793765821&text=Hoi%20Livia!' target={'_blank'} rel={'noopener noreferrer'}>
							Whatsapp
						</a>{' '}
						(+41 79 376 58 21) oder telefonisch bei mir. Wir treffen uns zu einem ersten, unverbindlichen Kennenlern-Gespräch. Ich mache euch einen Vorschlag,
						wie eure Trauung aussehen könnte. Wir treffen uns erneut, um die Details zu besprechen. Danach fehlt nur noch euer Ja-Wort{' '}
						<span aria-label={'smiley face'} role={'img'}>
							😊
						</span>
					</p>
				</SectionComponent>
				<SectionComponent id={'section-kosten'} icon={'💸'} iconLabel={'Kosten'} title={'Die Kosten'} subtitle={'Ein Preis, unendlich viele Möglichkeiten.'}>
					<p>
						Das erste Kennenlern-Gespräch ist unverbindlich und kostenlos. Gerne können wir einen Termin dafür vereinbaren.{' '}
						<strong>Der Fixpreis für die freie Trauung beträgt 1790.- Franken.</strong> Dazu gehören: Kommunikation (E-Mails, Briefe, Telefonate) Verfassen
						eurer individuellen Rede, Absprache mit Gästen (Trauzeugen, Eltern etc.) .. und zum Schluss natürlich die freie Trauung.
					</p>
				</SectionComponent>
				<SectionComponent id={'section-uebermich'} icon={'👋🏻'} iconLabel={'Say hi'} title={'Über mich'} subtitle={'Mutter, Familienmensch, Traurednerin.'}>
					<p>
						<strong>Innovativ, verantwortungsbewusst, lebensfroh.</strong> Mein Name ist Livia Sinz, ich wurde im Jahr 1991 geboren und wohne seither in
						Altstätten. Seit Mai 2018 bin ich selbst glücklich verheiratet und als freie Traurednerin im St. Galler Rheintal tätig. Nach meiner dreijährigen
						Ausbildung zur Kauffrau folgte ich meiner Berufung und wurde Zivilstandsbeamtin beim Zivilstandsamt Rheintal in Altstätten.
					</p>
					<p>
						Während meiner Zeit auf der Stadtverwaltung durfte ich unzählige bewegende Momente erleben und sammelte viel Erfahrung im Bereich der
						Ziviltrauungen. Ich liebe die Zusammenarbeit und den Kontakt mit Menschen, die sich dazu entschlossen haben, diesen einzigartigen Schritt in einer
						Beziehung zu machen. Im Jahr 2018, mit über sieben Jahren Erfahrung im Gepäck, schloss ich die Ausbildung zur Zeremonienleiterin bei «Traumich»
						(Zertifikat herunterladen) ab und entschied mich dazu, in Zukunft freie Trauungen anzubieten. Ich sehe es als meine Aufgabe, den schönsten Tag in
						eurem Leben noch unvergesslicher und berührender zu gestalten.
					</p>
					<p>
						So, wie ihr es euch wünscht, in der freien Natur, bei euch im Wohnzimmer oder im geselligen Restaurant bei euch um die Ecke. Ich bin gespannt auf
						eure Wünsche und freue mich auf ein unkompliziertes, unverbindliches Gespräch mit euch!
					</p>
				</SectionComponent>
				<SectionComponent id={'section-galerie'} icon={'📸'} iconLabel={'Camera'} title={'Fotogalerie'} subtitle={'Ein paar Eindrücke vergangener Trauungen.'}>
					<PhotoGalleryComponent data={galleryPhotos} />
				</SectionComponent>
				<SectionComponent
					id={'section-feedback'}
					icon={'🥰'}
					iconLabel={'Heart face'}
					title={'Feedback'}
					subtitle={'Einige schöne Rückmeldungen von vergangenen Trauungen.'}
				>
					<Testimonials photos={feedbackPhotos} />
				</SectionComponent>
				<SectionComponent id={'section-kontakt'} icon={'💬'} iconLabel={'Chat bubble'} title={'Kontakt'} subtitle={'Ich freue mich über eure Nachricht!'}>
					<p>
						Beratung, ganz unverbindlich und kostenlos. Schick mir doch eine E-Mail oder ruf mich an. Meine Kontaktdetails:
					</p>
					<h2 className={'mt-8'}>Kontaktangaben</h2>
					<div className={'grid grid-cols-1 gap-y-6 sm:grid-cols-2'}>
						<ul>
							<li>Livia Sinz</li>
							<li>Freie Traurednerin</li>
							<li>Marktgasse 14</li>
							<li>9450 Altstätten</li>
						</ul>
						<ul>
							<li>
								E-Mail: <a href='mailto:mail@livia-traut.ch'>mail@livia-traut.ch</a>{' '}
							</li>
							<li>
								Tel: <a href='tel:0041793765821'>079 376 58 21</a>
							</li>
							<li>
								Whatsapp:{' '}
								<a href={'https://api.whatsapp.com/send?phone=41793765821&text=Hoi%20Livia!'} target={'_blank'} rel={'noopener noreferrer'}>
									Chat starten
								</a>
							</li>
							<li>
								Instagram:{' '}
								<a href={'https://www.instagram.com/liviatraut/?hl=de'} target={'_blank'} rel={'noopener noreferrer'}>
									@liviatraut
								</a>
							</li>
						</ul>
					</div>
					{/* <ContactFormComponent /> */}
				</SectionComponent>
				<SectionComponent id={'section-anfahrt'} icon={'🗺️'} iconLabel={'Map'} title={'Anfahrt'} subtitle={'Nach Altstätten, im wunderschönen Rheintal.'}>
					<div className={'w-full h-1/2'}>
						<iframe
							title='google-maps-iframe'
							key='google-maps-iframe'
							frameBorder={0}
							src='https://www.google.com/maps/embed/v1/place?key=AIzaSyDp3sTSCOEPVejt7j3DMD-Gjp-j1-qxnU8&q=Marktgasse+14+9450+Altstaetten'
							width='100%'
							height='420px'
						/>
					</div>
				</SectionComponent>
				<Waypoint onEnter={() => setIsPlaying(true)} onLeave={() => setIsPlaying(true)} />
				<Transition from='#FFF' height={'100vh'} to='#DCB4C0' position={0} />
				<div className={'w-full flex-col h-screen items-center p-4 flex justify-center'}>
					{/* {isPlaying && <ConfettiComponent isPlaying={isPlaying} />} */}
					<ConfettiComponent isPlaying={isPlaying} />
					<div className={'w-2/5 mb-8'}>
						<Logo />
					</div>
					<h1 className={'text-white font-bold text-3xl md:text-7xl text-center max-w-4xl'}>
						Traut euch!{' '}
						<span role={'img'} aria-label={'festive'}>
							🎊
						</span>
						<span role={'img'} aria-label={'heart-face'}>
							🥰
						</span>{' '}
						Ich helfe sehr gerne dabei.
					</h1>
				</div>
			</AnimatedBg>
		</main>
	)
}

export const query = graphql`
	query HomePageQuery {
		allFile(filter: { sourceInstanceName: { eq: "photos" } }) {
			edges {
				node {
					id
					childImageSharp {
						gatsbyImageData(
							width: 1000
							placeholder: DOMINANT_COLOR
							formats: [AUTO, WEBP, AVIF]
						  )
					}
					relativePath
				}
			}
		}
	}
`

export default IndexPage
