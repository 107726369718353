import React from 'react'
import ReactCanvasConfetti from 'react-canvas-confetti'

const canvasStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
}

export default class ConfettiComponent extends React.Component {
  constructor(props) {
    super(props)
    this.isAnimationEnabled = false
    this.animationInstance = null
    this.nextTickAnimation = this.nextTickAnimation.bind(this)
    this.confettiTimer = 3000
  }

  componentDidMount() {
    this.startAnimation()
  }

  componentDidUpdate(prevProps) {
    // console.log(prevProps)
    if (this.props.isPlaying && (this.props !== prevProps)) {
      this.startAnimation()
    } else {
      this.pauseAnimation()
    }
  }

  makeShot = (angle, originX) => {
    this.animationInstance &&
      this.animationInstance({
        particleCount: 3,
        angle,
        spread: 33,
        origin: { x: originX },
        colors: ['#DCB4C0', '#ffffff'],
      })
  }

  nextTickAnimation = () => {
    this.makeShot(60, 0)
    this.makeShot(120, 1)
    if (this.isAnimationEnabled) requestAnimationFrame(this.nextTickAnimation)
  }

  startAnimation = () => {
    if (!this.isAnimationEnabled) {
      this.isAnimationEnabled = true
      this.nextTickAnimation()
      setTimeout(() => {
        this.pauseAnimation()
      }, this.confettiTimer)
    }
  }

  pauseAnimation = () => {
    this.isAnimationEnabled = false
  }

  stopAnimation = () => {
    this.isAnimationEnabled = false
    this.animationInstance && this.animationInstance.reset()
  }

  handlerClickStart = () => {
    this.startAnimation()
  }

  handlerClickPause = () => {
    this.pauseAnimation()
  }

  handlerClickStop = () => {
    this.stopAnimation()
  }

  getInstance = (instance) => {
    this.animationInstance = instance
  }

  componentWillUnmount() {
    this.isAnimationEnabled = false
  }

  render() {
    return (
      <>
        {/* <div>
          <button onClick={this.handlerClickStart}>Start</button>
          <button onClick={this.handlerClickPause}>Pause</button>
          <button onClick={this.handlerClickStop}>Stop</button>
        </div> */}
        <ReactCanvasConfetti
          refConfetti={this.getInstance}
          style={canvasStyles}
        />
      </>
    )
  }
}
