const TESTIMONIALS = [
	{
		name: 'Marcia und Philippe',
		date: '10.06.2022',
		photo: 'marcia-und-philippe',
		text: '«Livia macht den schönsten Tag perfekt. Sie trifft den Ton, macht die Trauung zu etwas sehr Persönlichem und Anrührendem. Sie weiss das Ambiente mit ihren Erzählungen zum knistern zu bringen. Sie bereitet sich sehr gut vor, das merkt man, wenn Sie die Geschichte des Paares zusammenfasst. Wir können Livia wärmstens empfehlen und wer sie nicht bucht, ist wirklich selber schuld 😊»',
	},
	{
		name: 'Claudia und Christoph',
		date: '24.06.2022',
		photo: 'claudia-und-christoph',
		text: '«Die Trauung war genauso unkompliziert und locker wie wir uns das gewünscht haben und wurde mit viel Elan und Witz vorgetragen. Die Gäste haben mitgemacht und mitgelacht. Durch Livias offene Art ist eine vertraute Atmosphäre entstanden, die uns alle begeistert hat. Wir Danken Livia für eine wunderschöne Trauung.»',
	},
	{
		name: 'Tania und Karl',
		date: '27.08.2022',
		photo: 'tania-und-karl',
		text: '«Von Herzen möchten wir uns für die wunderschöne Trauung bedanken. Nichts war so, wie wir uns vorgestellt hatten... sondern alles war einfach perfekt und passte genau zu uns!! Deine berührende Rede, deine Art...  die ganze Trauung waren TOP! Nicht nur wir, sondern alle Gäste haben sich sehr wohl gefühlt und werden diesen Tag nie vergessen! Danke!»',
	},
	{
		name: 'Fabienne und Dominik',
		date: '03.09.2022',
		photo: 'fabienne-und-dominik',
		text: '«Die unkomplizierte & offene Art von Livia hat uns sehr gefallen. Die Trauung war mega emotional und wunderschön. Auch die Liebesgeschichte erzählte Livia auf den Punkt genau und mit viel Humor. Für uns war es ein durchaus gelungener und perfekter Tag den wir nie vergessen werden.❤️»',
	},
	{
		name: 'Stefanie und Lukas',
		date: '13.08.2022',
		photo: 'stephanie-und-lukas',
		text: '«Unsere Trauung war dank Livia ein richtiges Highlight – so liebevoll verfasst und erzählt. Perfekt auf uns abgestimmt genau so, wie wir sind.»',
	},
	{
		name: 'Fabienne und Lukas',
		date: '02.07.2022',
		photo: 'fabienne-und-lukas',
		text: '«Livia hat unsere Trauung wunderschön und persönlich gestaltet. Mit der richtigen Portion Witz hat sie unsere Geschichte erzählt und der Rede Leichtigkeit verleiht. Für uns war die freie Trauung perfekt und wird uns immer in bester Erinnerung bleiben. Wir können Livia von ganzem Herzen weiterempfehlen.»',
	},
	{
		name: 'Natalie und Sascha',
		date: '17.09.2022',
		photo: '_natalie-und-sascha',
		text: '«Livia als Traurednerin gewinnen zu können was ein absoluter Glücksfall! Bereits beim Kennenlernen und auch später beim persönlichen Vorbereitungsgespräch haben wir uns sehr wohl gefühlt. Livia hat die Gespräche mit ihrer empathischen, lustigen sowie freundlichen Art aufgelockert und uns so unsere ganz persönliche Liebesgeschichte entlockt. Diese hat sie an der freien Trauung in wunderbaren Worten zusammengefasst. Wir haben gelacht und geweint – es war einfach wunderbar! Auch unsere Gäste haben die Zeremonie genossen und wir bekommen noch heute Komplimente für die schöne freie Trauung! Danke Livia, dass du zu einem perfekten Tag beigetragen hast ❤️»',
	},
	{
		name: 'Melissa und Steven',
		date: '30.09.2022',
		photo: 'melissa-und-steven',
		text: '«Unsere Trauung unter dem grossen Baum im Schlossgarten mit Blick auf den Bodensee war das Highlight unserer Hochzeit. Durch die lustige aber auch emotional sehr berührende Rede von Livia wurde die Trauung einzigartig und ganz persönlich. Für deine wundervolle Arbeit bedanken wir uns von ganzem Herzen.»',
	},
	{
		name: 'Svenja und Simon',
		date: '17.06.2023',
		photo: 'svenja-und-simon',
		text: '«Livia führt ihre Trauungen mit viel Herzblut durch. Angefangen beim Kennenlerngespräch bei ihr zu Hause bis nach der Trauung war alles super organisiert. Die Trauung war ein Highlight! Ihre geschriebene Liebesgeschichte (mit unseren Antworten zu ihren Fragen) war so humorvoll vorgetragen, dass die Gäste sowie auch wir schmunzeln oder lachen mussten. Diese tolle Liebesgeschichte werden wir so schnell nicht vergessen! ❤️ Auch der Rest der Trauung verlief ohne Probleme und wir durften auch mitentscheiden wie, wo, wann, was stattfinden soll. So liessen wir als Ritual auch Ballone steigen, die der Fotograf mit der Drohne dann aufnahm. Sie erklärte auch immer genau was als nächstes kommt. Livia liebt es freie Traurednerin zu sein und das sieht man ihr auch an. Durch ihre sympathische Art fühlt man sich wohl und das ist so wertvoll an der eigenen freien Trauung 🥰😊👍🏻»',
	},
	{
		name: 'Selina & Eric',
		date: '12.08.2023',
		photo: 'selina-und-eric',
		text: '«Wir möchten uns herzlich bei dir für die wundervolle Trauung am 12. August 2023 bedanken. Deine einfühlsamen Worte haben unsere Liebe perfekt eingefangen und die freie Trauung zu einem unvergesslichen Moment gemacht. Dein Humor und deine persönliche Note haben den Tag für uns magisch gemacht. Wir sind so dankbar, dich als Traurednerin gewählt zu haben und empfehlen dich von Herzen weiter ❤️»',
	},
	{
		name: 'Rebecca & Christoph',
		date: '26.08.2023',
		photo: 'rebecca-und-christoph',
		text: '«Liebe Livia – wir danken dir herzlich dafür, dass du unserem Fest der Liebe mit deinen Worten, eine persönliche Note und den passenden Rahmen gegeben hast. Livia hat unsere Liebesgeschichte humorvoll und mit viel Gefühl auf den Punkt gebracht. Für unsere deutschen Gäste hat sie davon sogar noch eine Übersetzung erstellt, was alle sehr geschätzt haben. Wir werden diese persönliche und emotionale Zeremonie nie vergessen.»',
	},
	{
		name: 'Natascha & Robin',
		date: '16.09.2023',
		photo: 'natascha-und-robin',
		text: '«Livia hat durch ihre lockere, humorvolle und sympathische Art unsere Trauung unvergesslich gestaltet. Wir werden diesen emotionalen Moment sehr gut in Erinnerung behalten. Auch von unseren Gästen haben wir ausschliesslich sehr positive Rückmeldungen erhalten. Dir Livia, möchten wir von ganzem Herzen dafür danken, dass du dazu beigetragen hast, dass unser Tag so besonders für uns wurde.»',
	},
]

export default TESTIMONIALS
