import React, { useState } from 'react'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline'
import Testimonial from './testimonial'

import TESTIMONIALS from '../data/testimonials'

const Testimonials = ({ photos }) => {
	const [currentlyActive, setCurrentlyActive] = useState(0)

	console.log(photos)

	const currentPhoto = photos.filter((el) => el.node.relativePath.includes(TESTIMONIALS[currentlyActive].photo))[0]

	const previousSlide = () => {
		if (currentlyActive - 1 < 0) {
			setCurrentlyActive(TESTIMONIALS.length - 1)
		} else {
			setCurrentlyActive(currentlyActive - 1)
		}
	}

	const nextSlide = () => {
		if (currentlyActive + 1 >= TESTIMONIALS.length) {
			setCurrentlyActive(0)
		} else {
			setCurrentlyActive(currentlyActive + 1)
		}
	}

	return (
		<div>
			<div className={'mt-10 mb-5 flex justify-between items-center'}>
				<button
					onClick={() => previousSlide()}
					type='button'
					className='inline-flex items-center p-3 text-white bg-pink-300 border border-transparent rounded-full shadow-sm hover:bg-pink-400 focus:outline-none focus:ring-2 focus:ring-pinke-500 focus:ring-offset-2 focus:ring-pink-500'
				>
					<ArrowLeftIcon className='w-6 h-6' aria-hidden='true' />
				</button>
				<button
					onClick={() => nextSlide()}
					type='button'
					className='inline-flex items-center p-3 text-white bg-pink-300 border border-transparent rounded-full shadow-sm hover:bg-pink-400 focus:outline-none focus:ring-2 focus:ring-pinke-500 focus:ring-offset-2 focus:ring-pink-500'
				>
					<ArrowRightIcon className='w-6 h-6' aria-hidden='true' />
				</button>
			</div>
			<Testimonial data={TESTIMONIALS[currentlyActive]} currentPhoto={currentPhoto} />
			<div className={'mt-10 mb-5 flex justify-between items-center'}>
				<button
					onClick={() => previousSlide()}
					type='button'
					className='inline-flex items-center p-3 text-white bg-pink-300 border border-transparent rounded-full shadow-sm hover:bg-pink-400 focus:outline-none focus:ring-2 focus:ring-pinke-500 focus:ring-offset-2 focus:ring-pink-500'
				>
					<ArrowLeftIcon className='w-6 h-6' aria-hidden='true' />
				</button>
				<button
					onClick={() => nextSlide()}
					type='button'
					className='inline-flex items-center p-3 text-white bg-pink-300 border border-transparent rounded-full shadow-sm hover:bg-pink-400 focus:outline-none focus:ring-2 focus:ring-pinke-500 focus:ring-offset-2 focus:ring-pink-500'
				>
					<ArrowRightIcon className='w-6 h-6' aria-hidden='true' />
				</button>
			</div>
		</div>
	)
}

export default Testimonials
